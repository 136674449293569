.introContent {
  background-image: url("../../assets/bgr_dark-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  left: 0px;
  text-align: center;
}
.intro p {
  font-size: 20px;
  text-align: center;
}
.wel-co {
  margin-bottom: 26px;
  margin-top: 0px;
  color: rgba(244, 244, 244, 0.75);
  font-size: 32px;
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
}
.wel-co-border {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 0.5px;
  background: rgba(255, 255, 255, 0.4);
}
.we-strive {
  margin-bottom: 20px;
  margin-top: 0px;
  color: #ffffff;
  font-size: 34px;
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
}
.special-start {
  margin-bottom: 0px;
  margin-top: 0px;
  color: rgba(255, 255, 255, 0.74);
  font-size: 18px;
  line-height: 1.7em;
  font-family: "Oswald", arial;
  font-weight: 100;
  letter-spacing: 0.1em;
}
.red-you {
  color: rgb(199, 34, 69);
  opacity: 1;
}
.get-started-btn {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
  color: #fff;
  border: 2px solid #e1e0e0;
  background: transparent;
  border-radius: 32px;
  text-transform: uppercase;
  font-family: "Oswald", arial;
  padding: 1vw 2vw 1vw 2vw;
}
.boc-spacing {
  height: 60px;
}
.get-started-btn:hover {
  background-color: #e1e0e0;
  color: black;
}

/* ==============Start greatCompany================== */
.card {
  flex: auto;
  font-family: "Oswald", arial;
  font-weight: 300;
  position: relative;
  color: black;
  padding: 0 2rem 1rem 2rem;
}
.card-content {
  padding: 1rem 6rem 6rem 6rem;
}
.great-company {
  flex: auto;
  font-family: "Oswald", arial;
  font-weight: 300;
  position: relative;
  color: black;
  padding-top: 3rem;
  text-align: center;
}
.great-company-title {
  margin-bottom: 10px;
  margin-top: 35px;
  color: #333333;
  font-size: 30px;
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1rem;
}
.great-company-content {
  margin-bottom: 20px;
  margin-top: 0px;
  color: #333333;
  font-size: 17px;
  line-height: 1.7em;
}

.company-img {
  padding: 2rem;
}

.img_title {
  color: #333;
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 19px;
  line-height: 24px;
}
.img_content {
  color: #777;
  padding: 0 2rem 0 2rem;
}
.content_border {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background: #eeeeee;
}

.great-red {
  color: #c72245;
}

.great-bookmark {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background: #eeeeee;
}
.bookmark {
  font-size: 16px;
  line-height: 16px;
  color: #c72245;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: #ffffff;
  padding: 1px;
  text-align: center;
  margin-top: 3rem;
}

/* ===========start Imgflow============= */
.flow-content {
  padding: 10rem;
  text-align: center;
  padding-top: 30vh;
  @media screen and (max-width: 768px) {
    padding: 2rem;
    text-align: center;
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}
.imgflow {
  width: 100%;
  height: 100%;
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}
@-moz-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}
@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}
@-ms-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}
@-o-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}

.background-flow {
  background-size: cover;
  width: 100%;
  height: auto;
  background: url(../../assets/back-img-flow.jpg) repeat-x;
  animation: animatedBackground 15s linear infinite;
  -moz-animation: animatedBackground 15s linear infinite;
  -webkit-animation: animatedBackground 15s linear infinite;
  -ms-animation: animatedBackground 15s linear infinite;
  -o-animation: animatedBackground 15s linear infinite;
}

.we-build {
  margin-bottom: 20px;
  margin-top: 0px;
  color: #ffffff;
  font-size: 34px;
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
}
.we-build-mind {
  font-family: "Droid Serif", Georgia, serif;
  font-style: italic;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.71);
  text-transform: none;
  letter-spacing: 0;
  font-size: 17px;
}

/* ================ourPortfolio section================= */
.our-portfolio {
  flex: auto;
  font-family: "Oswald", arial;
  font-weight: 300;
  position: relative;
  color: black;
  padding-top: 3rem;
  text-align: center;
}

.portfolio-img {
  padding: 0;
  transition: transform 0.2s;
}

.portfolio-img:hover {
  transform: scale(1.3);
}

.portfolio-card {
  flex: auto;
  font-family: "Oswald", arial;
  font-weight: 300;
  position: relative;
  color: black;
  padding: 1rem 2rem 2rem 2rem;
}

.img-cover {
  border-radius: 50%;
  position: relative;
  display: block;
  overflow: hidden;
  opacity: 0.999;
  transform: translate3d(0, 0, 0);
  border: 10px solid rgba(0, 0, 0, 0.04);
}
.portfolio-content {
  padding: 2rem 10rem 2rem 10rem;
}

@media screen and (max-width: 1024px) {
  .portfolio-content {
    padding: 2rem 4rem 2rem 4rem;
  }
  .card-content {
    padding: 1rem 6rem 6rem 6rem;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-content {
    padding: 2rem 6rem 2rem 6rem;
  }
  .card-content {
    padding: 1rem 2rem 4rem 2rem;
  }
}

@media screen and (max-width: 554px) {
  .portfolio-content {
    padding: 2rem 2rem 2rem 2rem;
  }
  .card-content {
    padding: 1rem 2rem 4rem 2rem;
  }
}

.level-red {
  color: #c72245;
}
.level-title {
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  margin-top: 0px;
  color: #333333;
}
.level_divider {
  margin-top: 0px;
  margin-bottom: 20px;
  width: 60px;
  height: 1px;
  background: #dddddd;
}

.skill-level {
  padding: 3rem 10rem 3rem 10rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.progressbar {
  padding: 0;
}
.progressbar-wrap {
  margin-top: 5px;
  margin-bottom: 18px;
  padding-right: 2rem;
}

.progress-title {
  color: #777;
  margin: 0 0 12px 0;
  font-weight: 600;
}

.more-about-our-company {
  @media screen and (max-width: 1024px) {
    padding-top: 4rem;
  }
}

.more-about-content {
  padding-top: 1rem;
}

.creative {
  background-image: url("../../assets/desk_fortuna-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
}
.creative-content {
  font-size: 23px;
}

.creative-content-letter {
  font: 15px / 1.7em "Open Sans", arial, sans-serif;
  color: #fff;
  font-weight: 300;
}

.create-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80px;
  height: 1px;
  background: rgba(255, 255, 255, 0.24);
}
.progress-percent {
  background-color: #333;
  color: #fff;
  text-shadow: none;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 5px;
  position: absolute;
  margin: -34px 0px 0px -44px;
}

.progress-percent::after {
  display: block;
  border-color: #333 transparent;
  bottom: -3px;
  border-style: solid;
  border-width: 4px 4px 0;
  left: 16px;
  position: absolute;
  content: "";
}
.progressbar-color {
  background-color: rgb(199, 34, 69);
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.16) 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.16) 50%,
    rgba(0, 0, 0, 0.16) 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
  background-size: 24px 24px;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px 24px;
  -o-background-size: 24px 24px;
}
/* =================myFields=================== */

.icon-expertise {
  color: #c72245;
  float: left;
}
.icon-text {
  margin-left: 30px;
}
.slider-letters {
  margin: 22px 24px 22px 52px;
  font-size: 15px;
  line-height: 26px;
  font-family: Droid Serif, Georgia, serif;
  font-style: italic;
  color: #777;
}
.slider-content {
  background-color: #777;
}
.slider-img {
  float: left;
}
.slider-btn {
  border-radius: 9999px;
  border-width: 1px;
  padding: 0;
}
