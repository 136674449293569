@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  button {
    @apply border py-2 px-4 rounded-full;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #353836;
  color: white;
  font-family: "Oswald", arial;
}

.App {
  background-color: #fefefe;
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
span,
p,
#menu > ul > li > a,
.heading_font,
#menu > ul > li ul > li > a {
  font-family: "Oswald", arial;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.errorimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  text-align: center;
  width: -webkit-fill-available;
}

.contact {
  background-image: url("../src/assets/city-updraft-pre-smush-original.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  text-align: center;
}

.contact-input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: rgb(139, 139, 140);
}
.contact-input:focus {
  border-color: green;
}
