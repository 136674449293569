.in-code-knight {
  background-image: url("../../assets/sky_scrapers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
}
.font-doris {
  font-family: "Droid Serif", Georgia, serif;
}

.demo-wrap {
  overflow: hidden;
  position: relative;
}

.service-highlight {
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.service-content {
  position: relative;
}
.progress-font {
  font-family: "Droid Serif", Georgia, serif;
}
.peo-img {
  background-image: url("../../assets/people-discussion-5069845_1280-1.jpg");
  background-position: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
