.fixed-header {
  background: rgba(255, 255, 255, 0.05);
  position: fixed;
  z-index: 3;
  @media screen and (max-width: 1024px) {
    background: #222;
  }
}

.header {
  background: #222;
  position: fixed;
  z-index: 3;
  @media screen and (max-width: 1024px) {
    background: #222;
  }
}
.logo-img {
  width: auto;
  @media screen and (max-width: 1024px) {
    line-height: 1.7em;
    display: block;
    position: relative;
    padding: 12px 30px;
  }
}

.nav-li {
  font-family: "Oswald", arial;
  font-weight: 300;
  text-decoration: none;
  display: block;
  position: relative;
  @media screen and (max-width: 1024px) {
    /* For mobile phones: */
    background: #222;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    color: white;
  }
}
.nav-li :hover {
  color: #c72245;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-underline-offset: 2.45rem;
  font-size: 14px;
}

.nav-ul {
  display: block;
}
.nav-a {
  font-size: 14px;
  line-height: 1.7em;
  display: block;
  position: relative;
  padding: 12px 30px;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.toggle-open {
  position: sticky;
  display: block;
  transition: 0.5s ease-in-out;
}

.toggle-close {
  display: none;
  transition: 0.5s ease-in-out;
}
.active {
  color: #c72245;
  font-size: 14px;
}
