.portfolio {
  background-image: url("../../assets/phone_bgr_dark-1-1200x600.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
}
.single-portfolio-bg {
  background-image: url("../../assets/phone_bgr_dark-1-1200x600.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-attachment: scroll;
  text-align: center;
}
